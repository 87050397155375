export const SEARCH_PARAMS_VOUCH_ID = "vouch";
export const SEARCH_PARAMS_LISTING_ID = "listing";
export const SEARCH_PARAMS_SHOW_GIVEN_KEY = "showGiven";
export const SEARCH_PARAMS_NEWSLETTER_OPT_OUT = "newsletterOptOut";
export const SEARCH_PARAMS_SHOW_ACCEPT_FORM_KEY = "acceptVouch";
export const SEARCH_PARAMS_PREVIEW = "preview";
export const SEARCH_PARAMS_SORT_KEY = "sort";
export const SEARCH_PARAMS_QUERY_KEY = "query";
export const SEARCH_PARAMS_FILTER_KEY = "filter";
export const SEARCH_PARAMS_TAB_KEY = "tab";
export const SEARCH_PARAMS_FIRST_NAME_KEY = "first_name";
export const SEARCH_PARAMS_LAST_NAME_KEY = "last_name";
export const SEARCH_PARAMS_COMPANY_ID = "company_id";
export const SEARCH_PARAMS_PENDING_COMPANY_ID = "pending_company_id";
export const SEARCH_PARAMS_INDICATED_PLAN_AT_SIGN_UP = "plan";
export const SEARCH_PARAMS_INDICATED_PERIOD_AT_SIGN_UP = "period";
export const SEARCH_PARAMS_INDICATED_CURRENCY_AT_SIGN_UP = "currency";
export const SEARCH_PARAMS_URL = "url";
export const SEARCH_PARAMS_TYPE = "type";
export const SEARCH_PARAMS_PENDING_VOUCH_ID = "pending";
export const SEARCH_PARAMS_SUCCESS = "success";
export const SEARCH_PARAMS_REQUEST_VOUCH = "requestVouch";
export const SEARCH_PARAMS_SHOW_FORM_KEY = "showForm";
export const SEARCH_PARAMS_SHOW_SELF_FORM_KEY = "vouchSelf";
export const SEARCH_PARAMS_DRAFT_ID = "draftId";
export const SEARCH_PARAMS_FORM_COLLAPSED = "collapsed";
export const SEARCH_PARAMS_SELECTED_VOUCHES = "selected";
export const SEARCH_PARAMS_PROFILE_MODE = "profileMode";
export const SEARCH_PARAMS_IMPROVE_CHANCES = "improveChances";