import { useEffect, useState } from "react";
import Link from "next/link";
import { type PageContent, type SupportedLanguage } from "@/utils/i18n";
import { cn } from "@/utils/tw";
import { Button } from "../ui/Button";
import { Divider } from "../ui/Divider";
import { Icon } from "../ui/Icon";
import { Text } from "../ui/Text";
const t = {
  title: {
    no: "Sjekk innboksen",
    en: "Check your inbox",
    dk: "Tjek din indbakke"
  },
  description: {
    no: `Vi har sendt en bekreftelsesmail til eposten din, gå til innboksen for å bekrefte at vi har riktig epost.`,
    en: `We have sent a confirmation email to your email, go to your inbox to confirm that we have the correct email.`,
    dk: `Vi har sendt en bekræftelsesmail til din e-mail, gå til din indbakke for at bekræfte, at vi har den korrekte e-mail.`
  },
  missing: {
    en: "Can't find your email? Make sure to check spam or junk folder.",
    no: "Finner ikke eposten din? Sjekk søppelpostmappen.",
    dk: "Kan ikke finde din e-mail? Sørg for at tjekke spam- eller junk-mappen."
  },
  inXSections: {
    en: seconds => `Retry in ${seconds} seconds`,
    no: seconds => `Prøv igjen om ${seconds} sekunder`,
    dk: seconds => `Prøv igen om ${seconds} sekunder`
  },
  retryNow: {
    en: "Send new link",
    no: "Send ny link",
    dk: "Send nyt link"
  },
  gmail: {
    no: "Åpne Gmail",
    en: "Open Gmail",
    dk: "Åbn Gmail"
  },
  outlook: {
    no: "Åpne Outlook",
    en: "Open Outlook",
    dk: "Åbn Outlook"
  }
} satisfies PageContent;
export const VerifyingAuthScreen = ({
  user,
  retry,
  lang,
  variant = "purple",
  className
}: {
  user: {
    email: string;
    firstName?: string;
    lastName?: string;
  };
  lang: SupportedLanguage;
  variant?: "purple" | "yellow";
  className?: string;
  retry?: () => void;
}) => {
  const [seconds, setSeconds] = useState(30);
  useEffect(() => {
    if (seconds > 0) {
      const interval = setTimeout(() => {
        setSeconds(currSeconds => {
          return currSeconds - 1;
        });
      }, 1000);
      return () => clearTimeout(interval);
    }
  }, [seconds]);
  return <div className={cn("relative flex flex-col items-center gap-4", className)} data-sentry-component="VerifyingAuthScreen" data-sentry-source-file="VerifyingAuthScreen.tsx">
      <div className="mt-4">
        <Icon icon="email" size="x-large" data-sentry-element="Icon" data-sentry-source-file="VerifyingAuthScreen.tsx" />
      </div>
      <Text.H5 data-sentry-element="unknown" data-sentry-source-file="VerifyingAuthScreen.tsx">{t.title[lang]}</Text.H5>
      <Text.P data-sentry-element="unknown" data-sentry-source-file="VerifyingAuthScreen.tsx">{t.description[lang]}</Text.P>
      {retry && <div className="flex flex-col items-center gap-2">
          <Divider />
          <Text.P muted>{t.missing[lang]}</Text.P>
          <Button size="lg" variant={`${variant}-light`} onClick={() => {
        retry();
        setSeconds(30);
      }} disabled={seconds > 0}>
            {seconds > 0 ? t.inXSections[lang](`${seconds}`) : t.retryNow[lang]}
          </Button>
          <Divider />
        </div>}
      <div className="flex gap-4">
        <Link href={`https://mail.google.com/mail/u/${user.email}/#search/from%3A%40vouch.careers+in%3Aanywhere+newer_than%3A1d`} target="_blank" data-sentry-element="Link" data-sentry-source-file="VerifyingAuthScreen.tsx">
          <Button size="lg" variant={variant} data-sentry-element="Button" data-sentry-source-file="VerifyingAuthScreen.tsx">
            {t.gmail[lang]}
          </Button>
        </Link>
        <Link href={`https://outlook.live.com/mail/?login_hint=${encodeURIComponent(user.email)}`} target="_blank" data-sentry-element="Link" data-sentry-source-file="VerifyingAuthScreen.tsx">
          <Button size="lg" variant={variant} data-sentry-element="Button" data-sentry-source-file="VerifyingAuthScreen.tsx">
            {t.outlook[lang]}
          </Button>
        </Link>
      </div>
    </div>;
};