"use client";

import { useCallback, useState } from "react";
import Link from "next/link";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { type PageContent } from "@/utils/i18n";
import { SEARCH_PARAMS_FIRST_NAME_KEY, SEARCH_PARAMS_LAST_NAME_KEY } from "@/utils/queryParams";
import { isNil, omitBy } from "lodash-es";
import { signIn } from "next-auth/react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../ui/Button";
import { Icon } from "../ui/Icon";
import { Input } from "../ui/Input";
import { Text } from "../ui/Text";
const t = {
  disclaimer: {
    en: "By creating an account, you consent to receive communications from Vouch. You can opt-out at anytime.",
    no: "Ved å opprette bruker samtykker du til å motta kommunikasjon fra Vouch. Du kan melde deg av når som helst.",
    dk: "Ved at oprette en bruger giver du samtykke til at modtage kommunikation fra Vouch. Du kan til enhver tid fravælge dette."
  },
  continueWithEmail: {
    en: "Continue with email",
    no: "Fortsett med epost",
    dk: "Fortsæt med e-mail"
  },
  email: {
    en: "Email",
    no: "Epost",
    dk: "E-mail"
  },
  firstName: {
    en: "First name",
    no: "Fornavn",
    dk: "Fornavn"
  },
  lastName: {
    en: "Last name",
    no: "Etternavn",
    dk: "Efternavn"
  },
  newsletter: {
    no: "Meld deg på vårt nyhetsbrev",
    en: "Sign up for our newsletter",
    dk: "Tilmeld dig vores nyhedsbrev"
  },
  terms: {
    terms: {
      en: "Terms and conditions",
      no: "Vilkår og betingelser",
      dk: "Betingelser og vilkår"
    },
    privacy: {
      en: "privacy policy",
      no: "personvernerklæring",
      dk: "privatlivspolitik"
    },
    and: {
      en: "and",
      no: "og",
      dk: "og"
    }
  }
} satisfies PageContent;
const LoginLogos = {
  linkedin: "/img/login/linkedin.png",
  google: "/img/login/google.svg",
  github: "/img/login/github.svg",
  email: ""
} as Partial<{ [key in string]: string }>;
export type EmailAuthContext = {
  voucherVouchId?: string;
  candidateVouchId?: string;
};
export const SignIn = ({
  onSignUpClicked,
  disabled,
  onVerifyOpen,
  includeNameForEmail,
  variant = "purple",
  emailContext = {}
}: {
  disabled?: boolean;
  onSignUpClicked?: (email: string, firstName?: string, lastName?: string) => PromiseLike<{
    callbackUrl?: string;
    abort: boolean;
  }>;
  onVerifyOpen?: (user: {
    email: string;
    firstName?: string;
    lastName?: string;
  }, retry: () => void) => string | void;
  includeNameForEmail?: boolean;
  variant?: "purple" | "yellow";
  emailContext?: EmailAuthContext;
}) => {
  const {
    lang
  } = useLocaleProvider();
  const [emailExpanded, setIsEmailExpanded] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const getCallbackUrl = useCallback((callbackUrl?: string) => {
    const url = new URL(
    // ? I think this URL might be too deeply nested // but ignoring that for now. Fixed magic link by separating out  callbackUrl serverside and passing it as distinct query param
    `${process.env.NEXT_PUBLIC_APP_BASE_URL}/signin/callback?url=${btoa(callbackUrl ? callbackUrl : window.location.pathname)}`);
    const searchParams = new URLSearchParams(url.search);
    if (includeNameForEmail) {
      searchParams.append(SEARCH_PARAMS_FIRST_NAME_KEY, firstName);
      searchParams.append(SEARCH_PARAMS_LAST_NAME_KEY, lastName);
    }
    return url.toString();
  }, [firstName, includeNameForEmail, lastName]);
  const [isLoading, setIsLoading] = useState(false);
  const isEmailValid = z.string().email().safeParse(email).success;
  const form = useForm();
  const onSubmit = form.handleSubmit(async () => {
    if (includeNameForEmail) {
      // this if was part of the original if statement below, but I think it makes more sense here separately
      if (!firstName) {
        return form.setError("firstName", {
          type: "required"
        });
      }
      if (!lastName) {
        return form.setError("lastName", {
          type: "required"
        });
      }
    }
    if (isEmailValid) {
      const {
        abort,
        callbackUrl
      } = onSignUpClicked ? await onSignUpClicked(email, firstName, lastName) : {
        abort: false,
        callbackUrl: undefined
      };
      if (!abort) {
        setIsLoading(true);
        signIn("email", {
          callbackUrl: getCallbackUrl(callbackUrl),
          email: email,
          redirect: false
        }, {
          first_name: firstName,
          last_name: lastName,
          ...omitBy(emailContext, isNil)
        }).then(() => {
          onVerifyOpen?.({
            email,
            firstName,
            lastName
          }, () => signIn("email", {
            callbackUrl: getCallbackUrl(callbackUrl),
            email: email,
            redirect: false
          }, {
            first_name: firstName,
            last_name: lastName,
            ...omitBy(emailContext, isNil)
          }));
          setIsLoading(false);
        }).catch(console.error);
      }
    }
  });
  return <div className="space-y-4" data-sentry-component="SignIn" data-sentry-source-file="SignIn.tsx">
      <div className="space-y-1">
        <Button variant="ghost" bordered className="h-16 w-full border-border" disabled={disabled || isLoading} onClick={async () => {
        if (isLoading || disabled) return;
        const {
          abort,
          callbackUrl
        } = onSignUpClicked ? await onSignUpClicked(email, firstName, lastName) : {
          abort: false,
          callbackUrl: undefined
        };
        if (!abort) {
          setIsLoading(true);
          signIn("linkedin", {
            callbackUrl: getCallbackUrl(callbackUrl)
          }).catch(console.error);
        }
      }} data-sentry-element="Button" data-sentry-source-file="SignIn.tsx">
          <picture className="relative h-10 w-10 object-contain pl-2.5 pr-1.5" data-sentry-element="picture" data-sentry-source-file="SignIn.tsx">
            <img src={LoginLogos.linkedin || ""} alt="" className="h-full w-full object-contain" />
          </picture>
          <Text.P className="flex-1 text-start" data-sentry-element="unknown" data-sentry-source-file="SignIn.tsx">LinkedIn</Text.P>
          <Icon icon="upRightArrow" variant="gray" data-sentry-element="Icon" data-sentry-source-file="SignIn.tsx" />
        </Button>
        <Button variant="ghost" bordered className="h-16 w-full border-border" disabled={disabled || isLoading} onClick={async () => {
        if (isLoading || disabled) return;
        const {
          abort,
          callbackUrl
        } = onSignUpClicked ? await onSignUpClicked(email, firstName, lastName) : {
          abort: false,
          callbackUrl: undefined
        };
        if (!abort) {
          setIsLoading(true);
          signIn("google", {
            callbackUrl: getCallbackUrl(callbackUrl)
          }).catch(console.error);
        }
      }} data-sentry-element="Button" data-sentry-source-file="SignIn.tsx">
          <picture className="relative h-10 w-10 object-contain" data-sentry-element="picture" data-sentry-source-file="SignIn.tsx">
            <img src={LoginLogos.google || ""} alt="" className="h-full w-full object-contain" />
          </picture>
          <Text.P className="flex-1 text-start" data-sentry-element="unknown" data-sentry-source-file="SignIn.tsx">Google</Text.P>
          <Icon icon="upRightArrow" variant="gray" data-sentry-element="Icon" data-sentry-source-file="SignIn.tsx" />
        </Button>
        <Button variant="ghost" bordered className="h-16 w-full border-border" disabled={disabled || isLoading} onClick={async () => {
        if (isLoading || disabled) return;
        const {
          abort,
          callbackUrl
        } = onSignUpClicked ? await onSignUpClicked(email, firstName, lastName) : {
          abort: false,
          callbackUrl: undefined
        };
        if (!abort) {
          setIsLoading(true);
          signIn("github", {
            callbackUrl: getCallbackUrl(callbackUrl)
          }).catch(console.error);
        }
      }} data-sentry-element="Button" data-sentry-source-file="SignIn.tsx">
          <picture className="relative h-6 w-10 object-contain" data-sentry-element="picture" data-sentry-source-file="SignIn.tsx">
            <img src={LoginLogos.github || ""} alt="" className="h-full w-full object-contain" />
          </picture>
          <Text.P className="flex-1 text-start" data-sentry-element="unknown" data-sentry-source-file="SignIn.tsx">Github</Text.P>
          <Icon icon="upRightArrow" variant="gray" data-sentry-element="Icon" data-sentry-source-file="SignIn.tsx" />
        </Button>
      </div>
      {emailExpanded ? <div className="flex flex-col gap-2 pt-2">
          {includeNameForEmail && <div className="flex gap-2">
              <Input className="w-0 flex-1" placeholder={t.firstName[lang]} value={firstName} onChange={e => setFirstName(e.target.value)} autoCapitalize="sentences" />
              <Input className="w-0 flex-1" placeholder={t.lastName[lang]} value={lastName} onChange={e => setLastName(e.target.value)} autoCapitalize="sentences" />
            </div>}
          <Input type="email" placeholder={t.email[lang]} value={email} disabled={isLoading || disabled} onChange={e => {
        setEmail(e.target.value);
      }} onKeyDown={e => {
        if (e.key === "Enter") {
          void onSubmit();
        }
      }} />

          <Button className="w-full" disabled={!isEmailValid || (includeNameForEmail ? !firstName || !lastName : false) || isLoading || disabled} variant={variant} rounded={false} bordered={false} icon="rightArrow" onClick={onSubmit}>
            {t.continueWithEmail[lang]}
          </Button>
        </div> : <div className="flex w-full justify-center">
          <Button variant="ghost" className="mx-auto underline opacity-60" onClick={() => setIsEmailExpanded(true)} disabled={isLoading || disabled}>
            {t.continueWithEmail[lang]}
          </Button>
        </div>}
      <Text.P level={4} muted data-sentry-element="unknown" data-sentry-source-file="SignIn.tsx">
        <Link target="_blank" className="underline" href="https://vouchcareers.notion.site/Terms-Conditions-7e875afef3584f62abab8eb72c58d75a?pvs=4" data-sentry-element="Link" data-sentry-source-file="SignIn.tsx">
          {t.terms.terms[lang]}
        </Link>
        {` ${t.terms.and[lang]} `}
        <Link href={"https://vouchcLinkreers.notion.site/Privacy-Policy-cdca4246ab3748bb979669c09adf5132?pvs=4"} className="underline" target="_blank" data-sentry-element="Link" data-sentry-source-file="SignIn.tsx">
          {t.terms.privacy[lang]}
        </Link>
        {". "}
        {t.disclaimer[lang]}
      </Text.P>
    </div>;
};