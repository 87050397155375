"use client";

import { cn } from "@/utils/tw";
export const Divider = (props: {
  className?: string;
  vertical?: boolean;
  children?: React.ReactNode;
  variant?: "default" | "light";
}) => {
  if (props.vertical) return <div className={cn("w-[1px] grow-0 self-stretch border-r border-black/20 dark:border-white/20", {
    "border-black/5 dark:border-white/5": props.variant === "light"
  }, props.className)} />;
  return <div className={cn("relative my-4 flex w-full items-center", props.className)} data-sentry-component="Divider" data-sentry-source-file="Divider.tsx">
      <div className={cn("h-[1px] flex-1 bg-black/20 dark:bg-white/20", {
      "bg-black/5 dark:bg-white/5": props.variant === "light"
    }, props.className)} />
      {props.children}
      <div className={cn("h-[1px] flex-1 bg-black/20 dark:bg-white/20", {
      "bg-black/5 dark:bg-white/5": props.variant === "light"
    }, props.className)} />
    </div>;
};